import React from 'react'
import Link from 'next/link'
import { translate, Trans } from 'react-i18next'
import { Container, Row, Col, Carousel } from './../../static/theme'
import { Typography, Image } from './../../components/ui'

const Footer = ({ t }) => (
  <div className="ds-footer">
    <footer>
      <Container>
        <Row className="align-items-center">
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className="ds-footer_left">
              <Typography variant="gray-light" disableGutterBottom dangerouslySetInnerHTML={{ __html: t('translation:footer.copy') }}/>
            </div>
          </Col>
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className="ds-footer_right">
              <Link href="/">
                <a><img src="../../static/images/brand-intrasites-white.png" className="img-fluid"/></a>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  </div>
)

export default translate('translation')(Footer)
