import React from 'react'
import { translate, Trans } from 'react-i18next'
import Slider from 'react-slick'
import { Container, Row, Col, Carousel } from './../../static/theme'
import { Typography, Image } from './../../components/ui'

import Collaborators from './../../static/images/icons/collaborators.svg'
import News from './../../static/images/icons/news.svg'
import Documents from './../../static/images/icons/documents.svg'
import Messenger from './../../static/images/icons/messenger.svg'
import Mobile from './../../static/images/icons/mobile.svg'
import Notifications from './../../static/images/icons/notifications.svg'
import Meetings from './../../static/images/icons/meetings.svg'
import Events from './../../static/images/icons/events.svg'
import Benefits from './../../static/images/icons/benefits.svg'
import Acknowledgments from './../../static/images/icons/acknowledgments.svg'
import Workflows from './../../static/images/icons/workflows.svg'

import Collaboration from './../../static/images/Colaboracion.png'
import Comunication from './../../static/images/Comunicacion.png'
import Productivity from './../../static/images/Productividad.png'

const images = [
  { name: "Comunication", src: Comunication },
  { name: "Collaboration", src: Collaboration },
  { name: "Productivity", src: Productivity },
]

class Pillars extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      active: 0,
      settings: {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (index) => {
          this.handleOnChange(index);
        }
      }
    }
  }

  handleOnChange = (index) => {
    this.setState({ active: index });
  }

  handleGoChange = (index) => {
    this._slick.slickGoTo(index);
  }

  render(){

    const { active } = this.state
    const { t } = this.props

    return(
      <div className="ds-pillars">

        <div className="ds-pillars__first">
          <Container>
            <Row className="justify-content-center">
              <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                  <div className="ds-pillars__video">
                    <Slider {...this.state.settings} ref={ function(c) { this._slick = c }.bind(this) }>

                      {
                        images.map(( image, index ) => {
                          return (
                            <div key={ index }>
                              <div className="ds-image__box">
                                <Image src={ image.src } alt={ image.name }/>
                              </div>
                            </div>
                          )
                        })
                      }

                    </Slider>

                    {/*
                    <div className="embed-responsive embed-responsive-16by9">
                      <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/1VQ_3sBZEm0?rel=0"></iframe>
                    </div>
                    */}
                  </div>
              </Col>
            </Row>
            <Row>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <div className="ds-pillars__items">
                  <Typography component="h3" weight="light" variant="gray" cursor="pointer" classes={ active === 0 ? 'text-active' : null } onClick={ () => {  this.handleGoChange(0) }}>
                    { t('translation:pillars.communication.title') }
                  </Typography>
                  <Typography component="p" variant="gray-light" dangerouslySetInnerHTML={{ __html: t('translation:pillars.communication.subtitle') }}/>
                </div>
              </Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <div className="ds-pillars__items">
                  <Typography component="h3" weight="light" variant="gray" cursor="pointer" classes={ active === 1 ? 'text-active' : null } onClick={ () => {  this.handleGoChange(1) }}>
                    { t('translation:pillars.collaboration.title') }
                  </Typography>
                  <Typography component="p" variant="gray-light" dangerouslySetInnerHTML={{ __html: t('translation:pillars.collaboration.subtitle') }}/>
                </div>
              </Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <div className="ds-pillars__items">
                  <Typography component="h3" weight="light" variant="gray" cursor="pointer" classes={ active === 2 ? 'text-active' : null }  onClick={ () => {  this.handleGoChange(2) }}>
                    { t('translation:pillars.productivity.title') }
                  </Typography>
                  <Typography component="p" variant="gray-light" dangerouslySetInnerHTML={{ __html: t('translation:pillars.productivity.subtitle') }}/>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="ds-pillars__second">
          <Container>
            <Row>

              <Col xl={2} lg={3} md={3} sm={6} xs={12}>
                <div className="ds-pilars__box box-xl-mb box-lg-mb box-md-mb">
                  <div className="ds-pilars__box-icon">
                    <Image src={ Collaborators } alt={ t('translation:pillars.options.collaborators') }/>
                  </div>
                  <div className="ds-pilars__box-label">
                    <Typography component="p" variant="gray-light" align="center" disableGutterBottom>
                      { t('translation:pillars.options.collaborators') }
                    </Typography>
                  </div>
                </div>
              </Col>

              <Col xl={2} lg={3} md={3} sm={6} xs={12}>
                <div className="ds-pilars__box box-xl-mb box-lg-mb box-md-mb">
                  <div className="ds-pilars__box-icon">
                    <Image src={ News } alt={ t('translation:pillars.options.news') }/>
                  </div>
                  <div className="ds-pilars__box-label">
                    <Typography component="p" variant="gray-light" align="center" disableGutterBottom>
                      { t('translation:pillars.options.news') }
                    </Typography>
                  </div>
                </div>
              </Col>

              <Col xl={2} lg={3} md={3} sm={6} xs={12}>
                <div className="ds-pilars__box box-xl-mb box-lg-mb box-md-mb">
                  <div className="ds-pilars__box-icon">
                    <Image src={ Documents } alt={ t('translation:pillars.options.documents') }/>
                  </div>
                  <div className="ds-pilars__box-label">
                    <Typography component="p" variant="gray-light" align="center" disableGutterBottom>
                      { t('translation:pillars.options.documents') }
                    </Typography>
                  </div>
                </div>
              </Col>

              <Col xl={2} lg={3} md={3} sm={6} xs={12}>
                <div className="ds-pilars__box box-xl-mb box-lg-mb box-md-mb">
                  <div className="ds-pilars__box-icon">
                    <Image src={ Messenger } alt={ t('translation:pillars.options.messenger') }/>
                  </div>
                  <div className="ds-pilars__box-label">
                    <Typography component="p" variant="gray-light" align="center" disableGutterBottom>
                      { t('translation:pillars.options.messenger') }
                    </Typography>
                  </div>
                </div>
              </Col>

              <Col xl={2} lg={3} md={3} sm={6} xs={12}>
                <div className="ds-pilars__box box-xl-mb box-lg-mb box-md-mb">
                  <div className="ds-pilars__box-icon">
                    <Image src={ Mobile } alt={ t('translation:pillars.options.mobile') }/>
                  </div>
                  <div className="ds-pilars__box-label">
                    <Typography component="p" variant="gray-light" align="center" disableGutterBottom>
                      { t('translation:pillars.options.mobile') }
                    </Typography>
                  </div>
                </div>
              </Col>

              <Col xl={2} lg={3} md={3} sm={6} xs={12}>
                <div className="ds-pilars__box box-xl-mb box-lg-mb box-md-mb">
                  <div className="ds-pilars__box-icon">
                    <Image src={ Notifications } alt={ t('translation:pillars.options.notifications') }/>
                  </div>
                  <div className="ds-pilars__box-label">
                    <Typography component="p" variant="gray-light" align="center" disableGutterBottom>
                      { t('translation:pillars.options.notifications') }
                    </Typography>
                  </div>
                </div>
              </Col>

              <Col xl={{ span: 2, offset: 1 }} lg={3} md={3} sm={6} xs={12}>
                <div className="ds-pilars__box box-md-mb">
                  <div className="ds-pilars__box-icon">
                    <Image src={ Meetings } alt={ t('translation:pillars.options.meetings') }/>
                  </div>
                  <div className="ds-pilars__box-label">
                    <Typography component="p" variant="gray-light" align="center" disableGutterBottom>
                      { t('translation:pillars.options.meetings') }
                    </Typography>
                  </div>
                </div>
              </Col>

              <Col xl={2} lg={3} md={3} sm={6} xs={12}>
                <div className="ds-pilars__box box-md-mb">
                  <div className="ds-pilars__box-icon">
                    <Image src={ Events } alt={ t('translation:pillars.options.events') }/>
                  </div>
                  <div className="ds-pilars__box-label">
                    <Typography component="p" variant="gray-light" align="center" disableGutterBottom>
                      { t('translation:pillars.options.events') }
                    </Typography>
                  </div>
                </div>
              </Col>

              <Col xl={2} lg={3} md={3} sm={6} xs={12}>
                <div className="ds-pilars__box">
                  <div className="ds-pilars__box-icon">
                    <Image src={ Benefits } alt={ t('translation:pillars.options.benefits') }/>
                  </div>
                  <div className="ds-pilars__box-label">
                    <Typography component="p" variant="gray-light" align="center" disableGutterBottom>
                      { t('translation:pillars.options.benefits') }
                    </Typography>
                  </div>
                </div>
              </Col>

              <Col xl={2} lg={3} md={3} sm={6} xs={12}>
                <div className="ds-pilars__box">
                  <div className="ds-pilars__box-icon">
                    <Image src={ Acknowledgments } alt={ t('translation:pillars.options.awards') }/>
                  </div>
                  <div className="ds-pilars__box-label">
                    <Typography component="p" variant="gray-light" align="center" disableGutterBottom>
                      { t('translation:pillars.options.awards') }
                    </Typography>
                  </div>
                </div>
              </Col>

              <Col xl={2} lg={3} md={3} sm={12} xs={12}>
                <div className="ds-pilars__box">
                  <div className="ds-pilars__box-icon">
                    <Image src={ Workflows } alt={ t('translation:pillars.options.workflows') }/>
                  </div>
                  <div className="ds-pilars__box-label">
                    <Typography component="p" variant="gray-light" align="center" disableGutterBottom>
                      { t('translation:pillars.options.workflows') }
                    </Typography>
                  </div>
                </div>
              </Col>

            </Row>
          </Container>
        </div>

      </div>
    )

  }

}

export default translate('translation')(Pillars)
