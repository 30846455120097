import React from 'react'
import { translate, Trans } from 'react-i18next'
import { Container, Row, Col } from './../../static/theme'
import { Typography, Image } from './../../components/ui'

import Communication from './../../static/images/icons/communication.svg'
import Collaboration from './../../static/images/icons/collaboration.svg'
import Productivity from './../../static/images/icons/productivity.svg'

const Featureds = ({ t }) => (

  <section className="ds-featureds">

    <Container>

      <Row>
        <Col xl={4} lg={4} md={12} sm={12} xs={12}>
          <div className="ds-featureds__box">
            <div className="ds-featureds__box-icon">
              <Image src={ Communication } alt={ t('translation:featureds.communication.title') }/>
            </div>
            <div className="ds-featureds__box-label">
              <Typography component="h4" align="center" weight="light" classes="h3">
                { t('translation:featureds.communication.title') }
              </Typography>
            </div>
            <div className="ds-featureds__box-list">
              <ul className="list-unstyled ds-featureds__box-items">
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.communication.options.01') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.communication.options.02') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.communication.options.03') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.communication.options.04') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.communication.options.05') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.communication.options.06') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.communication.options.07') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.communication.options.08') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.communication.options.09') }
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Col xl={4} lg={4} md={12} sm={12} xs={12}>
          <div className="ds-featureds__box">
            <div className="ds-featureds__box-icon">
              <Image src={ Collaboration } alt={ t('translation:featureds.collaboration.title') }/>
            </div>
            <div className="ds-featureds__box-label">
              <Typography component="h4" align="center" weight="light" classes="h3">
                { t('translation:featureds.collaboration.title') }
              </Typography>
            </div>
            <div className="ds-featureds__box-list">
              <ul className="list-unstyled ds-featureds__box-items">
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.collaboration.options.01') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.collaboration.options.02') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.collaboration.options.03') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.collaboration.options.04') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.collaboration.options.05') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.collaboration.options.06') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.collaboration.options.07') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.collaboration.options.08') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.collaboration.options.09') }
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
        </Col>
        <Col xl={4} lg={4} md={12} sm={12} xs={12}>
          <div className="ds-featureds__box">
            <div className="ds-featureds__box-icon">
              <Image src={ Productivity } alt={ t('translation:featureds.productivity.title') }/>
            </div>
            <div className="ds-featureds__box-label">
              <Typography component="h4" align="center" weight="light" classes="h3">
                { t('translation:featureds.productivity.title') }
              </Typography>
            </div>
            <div className="ds-featureds__box-list">
              <ul className="list-unstyled ds-featureds__box-items">
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.productivity.options.01') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.productivity.options.02') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.productivity.options.03') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.productivity.options.04') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.productivity.options.05') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.productivity.options.06') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.productivity.options.07') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.productivity.options.08') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.productivity.options.09') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.productivity.options.10') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.productivity.options.11') }
                  </Typography>
                </li>
                <li>
                  <Typography disableGutterBottom variant="secondary">
                    { t('translation:featureds.productivity.options.12') }
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>

    </Container>

  </section>

)

export default translate('translation')(Featureds)
