import React from 'react'
import PropTypes from 'prop-types'

const Typography = ({ children, component, variant, weight, align, cursor, classes, disableGutterBottom, ...rest }) => {

  let Component = component ? component : 'p';
  let Classes   = classes ? classes : null;

  if(variant){
    Classes = Classes ? `${ Classes } text-${ variant }` : `text-${ variant }`;
  }

  if(weight){
    Classes = Classes ? `${ Classes } weight-${ weight }` : `weight-${ weight }`;
  }

  if(align){
    Classes = Classes ? `${ Classes } text-${ align }` : `text-${ align }`;
  }

  if(cursor){
    Classes = Classes ? `${ Classes } cursor-${ cursor }` : `cursor-${ cursor }`;
  }

  if(disableGutterBottom){
    Classes = Classes ? `${ Classes } mb-0` : `mb-0`;
  }

  return(
    <Component className={ Classes } {...rest }>{ children }</Component>
  )
}

Typography.propTypes = {
  children: PropTypes.node
};

export default Typography;
