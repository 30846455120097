import React from 'react'
import Slider from 'react-slick'
import { Container, Row, Col, Carousel } from './../../static/theme'
import { Typography, Image } from './../../components/ui'

import Visa from "./../../static/images/clients/Visa.svg"
import Andreani from "./../../static/images/clients/Andreani.svg"
import Banelco from "./../../static/images/clients/Banelco.svg"
import Grimoldi from "./../../static/images/clients/Grimoldi.svg"
import Ledesma from "./../../static/images/clients/Ledesma.svg"
import Lojack from "./../../static/images/clients/Lojack.svg"
import Natura from "./../../static/images/clients/Natura.svg"
import Zurich from "./../../static/images/clients/Zurich.svg"

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 5
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3
      }
    }
  ]
}

const clients = [
  { name: "Visa", icon: Visa },
  { name: "Ledesma", icon: Ledesma },
  { name: "Andreani", icon: Andreani },
  { name: "Grimoldi", icon: Grimoldi },
  { name: "Lojack", icon: Lojack },
  { name: "Banelco", icon: Banelco },
  { name: "Natura", icon: Natura },
  { name: "Zurich", icon: Zurich }
]

const Clients = () => (

  <section className="ds-brands">

    <Container>

      <Slider {...settings}>

        {
          clients.map(( client, index ) => {
            return (
              <div key={ index }>
                <div className="ds-brands__box">
                  <Image src={ client.icon } alt={ client.name }/>
                </div>
              </div>
            )
          })
        }

      </Slider>

    </Container>

  </section>

)

export default Clients
