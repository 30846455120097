import Head from 'next/head'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Header from './../shared/header'
import Footer from './../shared/footer'

const Layout = ({ children, containerType, headerPosition, title = 'Intrasites' }) => (

  <div className="ds--layout__default">

    <Head>
      <title>{ title }</title>
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-136844371-1"></script>
      <script dangerouslySetInnerHTML={{__html: `
        window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-136844371-1');`}} />
    </Head>

    <Header position={ headerPosition }/>

    <div className={
        classNames(
          "ds--container",
          (containerType ? `container-${ containerType }` : "container"),
          //(headerPosition ? `ds--container--header__${ headerPosition }` : null)
        ) }>
      { children }
    </div>

    <Footer/>

  </div>

);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  headerPosition: PropTypes.oneOf(['transparent', 'fixed']),
  containerType: PropTypes.oneOf(['fluid', 'all'])
};

export default Layout;
