import React from 'react'
import { translate, Trans } from 'react-i18next'
import { Container, Row, Col, Carousel } from './../../static/theme'
import { Typography, Image } from './../../components/ui'

import Request from './../../components/shared/request'

const Demo = ({ t }) => (

  <section className="ds-demo">

    <Container>

      <div className="ds-demo__header">
        <Row>
          <Col xl={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }} sm={12} xs={12}>
            <Typography variant="inverse" component="h1" weight="light" align="center" dangerouslySetInnerHTML={{ __html: t('translation:demo.title') }}/>

            <Typography variant="gray-light-min" align="center">
              { t('translation:demo.subtitle') }
            </Typography>

            <div className="ds-section_teaserCall">
              <Request/>
            </div>
            
          </Col>
        </Row>
      </div>

    </Container>

  </section>

)

export default translate('translation')(Demo)
