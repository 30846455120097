import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Image = ({ src, alt, classes, ...rest }) => {

  let Classes   = 'img-fluid';


    Classes = classes ? `${ Classes } ${ classes }` : Classes;


  return (
    <img src={ src } alt={ alt } { ...rest } className={ Classes }/>
  )
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default Image
