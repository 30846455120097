import request from './../../utilities/request'

function getClientIp(values) {
  return request({
    url:    '/ip/public',
    method: 'GET'
  });
}

const IpService = {
  getClientIp
}

export default IpService;
