import request from './../../utilities/request'

function send(values) {
  return request({
    url:    '/mailing/send',
    method: 'POST',
    data:   values
  });
}

const MailingService = {
  send
}

export default MailingService;
