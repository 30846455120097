import React from 'react'
import Particles from 'react-particles-js'
import { translate, Trans } from 'react-i18next'
import { Container, Row, Col } from './../../static/theme'
import { Typography } from './../../components/ui'

import Request from './../../components/shared/request'

const Teaser = ({ t }) => (

  <div className="ds-teaser ds--teaser--header__transparent">
    <Container>

      <section className="ds-section_particles">
        <Particles params={{
            "particles": {
              "number": {
	               "value": 100,
  	            "density": {
  	                "enable": true,
  	                "value_area": 1500
  	            }
    	        },
    	        "line_linked": {
    	            "enable": true,
    	            "opacity": 0.5
    	        },
    	        "move": {
    	            //"direction": "right",
    	            "speed": 1
    	        },
    	        "size": {
    	            "value": 1
    	        },
    	        "opacity": {
    	            "anim": {
    	                "enable": true,
    	                "speed": 1,
    	                "opacity_min": 0.05
    	            }
    	        }
            },
            "retina_detect": true
          }}/>
      </section>

      <section className="ds-section_teaser">

        <Typography component="h1" classes="ds-section_teaserTextPrimary" dangerouslySetInnerHTML={{ __html: t('translation:teaser.title') }}/>

        <div className="ds-section_teaserOptions">
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="primary" weight="bold" classes="ds-section_teaserOptions-title">
                { t('teaser.subtitle') }
              </Typography>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} xs={12}>
              <Typography variant="white" weight="light" classes="ds-section_teaserOptions-subtitle mb-sm mb-xs" dangerouslySetInnerHTML={{ __html: t('translation:teaser.block_01') }}/>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} xs={12}>
              <Typography variant="white" weight="light" classes="ds-section_teaserOptions-subtitle mb-sm mb-xs" dangerouslySetInnerHTML={{ __html: t('translation:teaser.block_02') }}/>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} xs={12}>
              <Typography variant="white" weight="light" classes="ds-section_teaserOptions-subtitle mb-xs" dangerouslySetInnerHTML={{ __html: t('translation:teaser.block_03') }} />
            </Col>
            <Col xl={3} lg={3} md={3} sm={6} xs={12}>
              <Typography variant="white" weight="light" classes="ds-section_teaserOptions-subtitle mb-xs" dangerouslySetInnerHTML={{ __html: t('translation:teaser.block_04') }}/>
            </Col>
          </Row>
        </div>


        <div className="ds-section_teaserCall">
          <Request/>
        </div>

      </section>
    </Container>
  </div>

)

export default translate('translation')(Teaser)
