import React from 'react'
import { translate, Trans } from 'react-i18next'
import { Container, Row, Col } from './../../static/theme'
import { Typography, Image } from './../../components/ui'

import Hosting from './../../static/images/icons/hosting.svg'
import Support from './../../static/images/icons/support.svg'
import Improvements from './../../static/images/icons/improvements.svg'
import AdditionalModules from './../../static/images/icons/additionalModules.svg'
import Discount from './../../static/images/icons/discount.svg'

class Prices extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      active: 0,
      business: {
        ars: {
          monthly: '500',
          yearly: '400',
          total: '4.800'
        },
        usd: {
          monthly: '500',
          yearly: '400',
          total: '4.800'
        }
      },
      enterprise: {
        ars: {
          monthly: '750',
          yearly: '600',
          total: '7.200'
        },
        usd: {
          monthly: '750',
          yearly: '600',
          total: '7.200'
        }
      },
      premium: {
        ars: {
          monthly: '1000',
          yearly: '800',
          total: '9.600'
        },
        usd: {
          monthly: '1000',
          yearly: '800',
          total: '9.600'
        }
      }
    };
  }

  handleChange = (type) => {

    this.setState({ active: type });

  }

  handlePriceMonthly = (plan) => {
    return this.state[plan][this.handleCurrentCurrency()].monthly;
  }

  handlePriceYearly = (plan) => {
    return this.state[plan][this.handleCurrentCurrency()].total;
  }

  handlePriceByState = (plan) => {

    if(this.state.active === 0) { // monthly

      return this.state[plan][this.handleCurrentCurrency()].monthly;

    }else{ // yearly

      return this.state[plan][this.handleCurrentCurrency()].yearly;

    }

  }

  handleCurrentCurrency = () => {
    return this.props.lng === 'es' ? 'ars' : 'usd';
  }

  render(){

    const { active, business, enterprise, premium } = this.state
    const { t } = this.props

    return(
      <section className="ds-prices">

        <Container>

          <div className="ds-prices__header">
            <Row>
              <Col xl={{ span: 10, offset: 1 }} lg={{ span: 10, offset: 1 }} md={{ span: 10, offset: 1 }} sm={12} xs={12}>
                <Typography component="h1" weight="light" align="center" classes="ds-prices__header-title" dangerouslySetInnerHTML={{ __html: t('translation:prices.title') }}/>
                <Typography variant="secondary" align="center" dangerouslySetInnerHTML={{ __html: t('translation:prices.subtitle') }}/>
              </Col>
            </Row>
          </div>

          <div className="ds-prices__body">
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="ds-prices__buttons">
                  <div>
                    <button type="button" className={ `btn btn-lg btn-${ active === 0 ? 'primary' : 'secondary' }`} onClick={ () => { this.handleChange(0) } }>
                      { t('translation:prices.buttons.monthly') }
                    </button>
                  </div>
                  <div>
                    <button type="button" className={ `btn btn-lg btn-${ active === 1 ? 'primary' : 'secondary' }`} onClick={ () => { this.handleChange(1) } }>
                      { t('translation:prices.buttons.yearly') }
                    </button>
                    <Image src={ Discount } alt="-20%" classes="ds-prices__buttons-discount"/>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                <div className="ds-prices__card">
                  <div className="ds-prices__card-name business">
                    <Typography component="h2" align="center" classes="ds-prices__card-name-label">
                      Business
                    </Typography>
                  </div>
                  <div className="ds-prices__card-price">
                    <Typography component="h3" align="center" weight="bold" classes="ds-prices__card-price-number">
                      <span>{ t('translation:prices.label.currency') }</span>{ this.handlePriceByState('business') }
                    </Typography>
                    { active === 0 ? (
                      <Typography component="span" weight="bold" align="center" classes="ds-prices__card-price-secondLabel">
                        { t('translation:prices.label.per_month') }
                      </Typography>
                    ) : (
                      <div>
                        <Typography component="span" variant="gray-light" align="center" classes="ds-prices__card-price-throughLabel">
                          <span>{ t('translation:prices.label.currency') }</span>{ this.handlePriceMonthly('business') }
                        </Typography>
                        <Typography component="span" variant="gray-light" align="center" classes="ds-prices__card-price-anualLabel">
                          { t('translation:prices.label.annual_payment_of') } <span>{ t('translation:prices.label.currency') }</span>{ this.handlePriceYearly('business') }
                        </Typography>
                      </div>
                    )}
                  </div>
                  <div className="ds-prices__card-list">
                    <Typography component="p" variant="secondary" align="center" classes="ds-prices__card-list-subtitle">
                      { t('translation:prices.plans.business.subtitle') }
                    </Typography>
                    <ul className="list-unstyled ds-prices__card-list-items">
                      <li>
                        <span className="fa fa-icon fa-check"></span>
                        <Typography disableGutterBottom variant="secondary" dangerouslySetInnerHTML={{ __html: t('translation:prices.options.users') }}/>
                      </li>
                      <li>
                        <span className="fa fa-icon fa-check"></span>
                        <Typography disableGutterBottom variant="secondary" dangerouslySetInnerHTML={{ __html: t('translation:prices.options.hosting') }}/>
                      </li>
                      <li>
                        <span className="fa fa-icon fa-check"></span>
                        <Typography disableGutterBottom variant="secondary" dangerouslySetInnerHTML={{ __html: t('translation:prices.options.communication') }}/>
                      </li>
                      <li>
                        <span className="fa fa-icon fa-check"></span>
                        <Typography disableGutterBottom variant="secondary" dangerouslySetInnerHTML={{ __html: t('translation:prices.options.collaboration') }}/>
                      </li>
                      <li>
                        <span className="fa fa-icon fa-check"></span>
                        <Typography disableGutterBottom variant="secondary" dangerouslySetInnerHTML={{ __html: t('translation:prices.options.productivity_2') }}/>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                <div className="ds-prices__card">
                  <div className="ds-prices__card-name enterprise">
                    <Typography component="h2" align="center" classes="ds-prices__card-name-label">
                      Enterprise
                    </Typography>
                  </div>
                  <div className="ds-prices__card-price">
                    <Typography component="h3" align="center" weight="bold" classes="ds-prices__card-price-number">
                      <span>{ t('translation:prices.label.currency') }</span>{ this.handlePriceByState('enterprise') }
                    </Typography>
                    { active === 0 ? (
                      <Typography component="span" weight="bold" align="center" classes="ds-prices__card-price-secondLabel">
                        { t('translation:prices.label.per_month') }
                      </Typography>
                    ) : (
                      <div>
                        <Typography component="span" variant="gray-light" align="center" classes="ds-prices__card-price-throughLabel">
                          <span>{ t('translation:prices.label.currency') }</span>{ this.handlePriceMonthly('enterprise') }
                        </Typography>
                        <Typography component="span" variant="gray-light" align="center" classes="ds-prices__card-price-anualLabel">
                          { t('translation:prices.label.annual_payment_of') } <span>{ t('translation:prices.label.currency') }</span>{ this.handlePriceYearly('enterprise') }
                        </Typography>
                      </div>
                    )}
                  </div>
                  <div className="ds-prices__card-list">
                    <Typography component="p" variant="secondary" align="center" classes="ds-prices__card-list-subtitle">
                      { t('translation:prices.plans.enterprise.subtitle') }
                    </Typography>
                    <ul className="list-unstyled ds-prices__card-list-items">
                      <li>
                        <span className="fa fa-icon fa-check"></span>
                        <Typography disableGutterBottom variant="secondary" dangerouslySetInnerHTML={{ __html: t('translation:prices.options.users') }}/>
                      </li>
                      <li>
                        <span className="fa fa-icon fa-check"></span>
                        <Typography disableGutterBottom variant="secondary" dangerouslySetInnerHTML={{ __html: t('translation:prices.options.hosting') }}/>
                      </li>
                      <li>
                        <span className="fa fa-icon fa-check"></span>
                        <Typography disableGutterBottom variant="secondary" dangerouslySetInnerHTML={{ __html: t('translation:prices.options.communication') }}/>
                      </li>
                      <li>
                        <span className="fa fa-icon fa-check"></span>
                        <Typography disableGutterBottom variant="secondary" dangerouslySetInnerHTML={{ __html: t('translation:prices.options.collaboration') }}/>
                      </li>
                      <li>
                        <span className="fa fa-icon fa-check"></span>
                        <Typography disableGutterBottom variant="secondary" dangerouslySetInnerHTML={{ __html: t('translation:prices.options.productivity_4') }}/>
                      </li>
                      <li>
                        <span className="fa fa-icon fa-check fa-icon-red"></span>
                        <Typography disableGutterBottom variant="danger" dangerouslySetInnerHTML={{ __html: t('translation:prices.options.strategic_advice') }}/>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                <div className="ds-prices__card">
                  <div className="ds-prices__card-name premium">
                    <Typography component="h2" align="center" classes="ds-prices__card-name-label">
                      Premium
                    </Typography>
                  </div>
                  <div className="ds-prices__card-price">
                    <Typography component="h3" align="center" weight="bold" classes="ds-prices__card-price-number">
                      <span>{ t('translation:prices.label.currency') }</span>{ this.handlePriceByState('premium') }
                    </Typography>
                    { active === 0 ? (
                      <Typography component="span" weight="bold" align="center" classes="ds-prices__card-price-secondLabel">
                        { t('translation:prices.label.per_month') }
                      </Typography>
                    ) : (
                      <div>
                        <Typography component="span" variant="gray-light" align="center" classes="ds-prices__card-price-throughLabel">
                          <span>{ t('translation:prices.label.currency') }</span>{ this.handlePriceMonthly('premium') }
                        </Typography>
                        <Typography component="span" variant="gray-light" align="center" classes="ds-prices__card-price-anualLabel">
                          { t('translation:prices.label.annual_payment_of') } <span>{ t('translation:prices.label.currency') }</span>{ this.handlePriceYearly('premium') }
                        </Typography>
                      </div>
                    )}
                  </div>
                  <div className="ds-prices__card-list">
                    <Typography component="p" variant="secondary" align="center" classes="ds-prices__card-list-subtitle">
                      { t('translation:prices.plans.premium.subtitle') }
                    </Typography>
                    <ul className="list-unstyled ds-prices__card-list-items">
                      <li>
                        <span className="fa fa-icon fa-check"></span>
                        <Typography disableGutterBottom variant="secondary" dangerouslySetInnerHTML={{ __html: t('translation:prices.options.users') }}/>
                      </li>
                      <li>
                        <span className="fa fa-icon fa-check"></span>
                        <Typography disableGutterBottom variant="secondary" dangerouslySetInnerHTML={{ __html: t('translation:prices.options.hosting') }}/>
                      </li>
                      <li>
                        <span className="fa fa-icon fa-check"></span>
                        <Typography disableGutterBottom variant="secondary" dangerouslySetInnerHTML={{ __html: t('translation:prices.options.communication') }}/>
                      </li>
                      <li>
                        <span className="fa fa-icon fa-check"></span>
                        <Typography disableGutterBottom variant="secondary" dangerouslySetInnerHTML={{ __html: t('translation:prices.options.collaboration') }}/>
                      </li>
                      <li>
                        <span className="fa fa-icon fa-check"></span>
                        <Typography disableGutterBottom variant="secondary" dangerouslySetInnerHTML={{ __html: t('translation:prices.options.productivity_6') }}/>
                      </li>
                      <li>
                        <span className="fa fa-icon fa-check fa-icon-red"></span>
                        <Typography disableGutterBottom variant="danger" dangerouslySetInnerHTML={{ __html: t('translation:prices.options.strategic_advice') }}/>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="ds-prices__footer">
            <div className="ds-prices__footer-top">
              <Typography component="h3" weight="light" align="center" classes="h2">
                { t('translation:prices.support.title') }
              </Typography>
            </div>
            <div className="ds-prices__includes">
              <Row>
                <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                  <div className="ds-prices__includes-box">
                    <div className="ds-prices__includes-box-icon">
                      <Image src={ Hosting } alt={ t('translation:prices.support.hosting_title') }/>
                    </div>
                    <div className="ds-prices__includes-box-label">
                      <Typography component="h4" align="center" weight="light" classes="h3">
                        { t('translation:prices.support.hosting_title') }
                      </Typography>
                      <Typography component="p" variant="gray-light" align="center" disableGutterBottom>
                        { t('translation:prices.support.hosting_subtitle') }
                      </Typography>
                    </div>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                  <div className="ds-prices__includes-box">
                    <div className="ds-prices__includes-box-icon">
                      <Image src={ Support } alt={ t('translation:prices.support.support_title') }/>
                    </div>
                    <div className="ds-prices__includes-box-label">
                      <Typography component="h4" align="center" weight="light" classes="h3">
                        { t('translation:prices.support.support_title') }
                      </Typography>
                      <Typography component="p" variant="gray-light" align="center" disableGutterBottom>
                        { t('translation:prices.support.support_subtitle') }
                      </Typography>
                    </div>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                  <div className="ds-prices__includes-box">
                    <div className="ds-prices__includes-box-icon">
                      <Image src={ Improvements } alt={ t('translation:prices.support.improvements_title') }/>
                    </div>
                    <div className="ds-prices__includes-box-label">
                      <Typography component="h4" align="center" weight="light" classes="h3">
                        { t('translation:prices.support.improvements_title') }
                      </Typography>
                      <Typography component="p" variant="gray-light" align="center" disableGutterBottom>
                        { t('translation:prices.support.improvements_subtitle') }
                      </Typography>
                    </div>
                  </div>
                </Col>
                <Col xl={3} lg={3} md={6} sm={12} xs={12}>
                  <div className="ds-prices__includes-box">
                    <div className="ds-prices__includes-box-icon">
                      <Image src={ AdditionalModules } alt={ t('translation:prices.support.additional_modules_title') }/>
                    </div>
                    <div className="ds-prices__includes-box-label">
                      <Typography component="h4" align="center" weight="light" classes="h3">
                        { t('translation:prices.support.additional_modules_title') }
                      </Typography>
                      <Typography component="p" variant="gray-light" align="center" disableGutterBottom>
                        { t('translation:prices.support.additional_modules_subtitle') }
                      </Typography>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

        </Container>

      </section>
    )

  }

}

export default translate('translation')(Prices)
