import React from 'react'
import { translate, Trans } from 'react-i18next'
import { MailingService  } from './../../services'
import { isEmail } from './../../utilities/utils'

class Request extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      sending:   false,
      success:   false,
      error:     false,
      code:      null
    }
    this.inputField = React.createRef();
  }

  handleSubmit = () => {

    if(!isEmail(this.inputField.value)){

      this.setState({ sending: true });

      MailingService.send({ email: this.inputField.value }).then((response) => {

        this.setState({
          sending: false,
          error: false,
          success: true,
          code: response.code
        });

        this.inputField.value = '';

        setTimeout(() => {
          this.setState({
            sending: false,
            error: false,
            success: false,
            code: null
          });
        }, 8000 );

      }).catch((error) => {

        this.setState({
          sending: false,
          error: true,
          success: false,
          code: error.data.code
        });

      });

    }else{

      this.setState({
        sending: false,
        error: true,
        success: false,
        code: 'EMAIL_CHECK_INVALID'
      });

    }

  }

  render(){

    const { t } = this.props
    const { sending, success, error, code } = this.state

    return(
      <div className="ds-section_teaserCall__form">
        <div className="ds-section_teaserCall__formMain">
          <div className="ds-section_teaserCall__formInput">
            <input ref={elem => this.inputField = elem} type="email" className="form-control transparent-input" placeholder={ t('translation:demo.form.placeholder') }/>
          </div>
          <button type="button" className="btn btn-primary" onClick={ this.handleSubmit } disabled={ sending }>
            { t('translation:demo.form.send') }
          </button>
        </div>
        { (error || success ) && (
          <div className={ `ds-section_teaserCall__formValidation ${ success ? 'success' : 'error' }`}>
            { t(`translation:demo.form.validations.${ code }`) }
          </div>
        )}
      </div>
    )
  }

}

export default translate('translation')(Request)
